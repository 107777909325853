/* eslint-disable @typescript-eslint/naming-convention */
import { ITaskDetailResponse } from '@/types/Achievements';
import { IBlueprintDetailProps } from '@/types/Blueprint';
import { IGetRandomAvatar } from '@/types/ChooseAvatar/res';
import {
  ICategoryList,
  IExplore,
  IFeaturedItemDetail,
  IFeatureProfile,
  INewsDetail,
  IPlanetDetails,
  IProfileUser,
  ISliderList,
} from '@/types/DTO/marketplace';
import { ITicket } from '@/types/DTO/miniGame';
import { IDropdown } from '@/types/Forms';
import { IFilterByMarketplace } from '@/types/Marketplace';
import { IMetaTags } from '@/types/MetaTags';
import { IBuilderSlider, IPartnerSlider, IPoweredBy } from '@/types/Sliders/partners';
import { ISignMessage, IWalletDetail } from '@/types/Wallet';
import api from '../api/services';
import api2 from '../api/services/marketplace';

export const _getCategoryList = () =>
  new Promise<ICategoryList>((resolve: (res: ICategoryList) => void, reject: (err: any) => void) => {
    api
      .categoryList()
      .then((res) => {
        resolve(res.data as ICategoryList);
      })
      .catch((err) => reject(err));
  });

export const _getSliderList = () =>
  new Promise<ISliderList>((resolve: (res: ISliderList) => void, reject: (err: any) => void) => {
    api
      .getSliderData()
      .then((res) => {
        resolve(res.data as ISliderList);
      })
      .catch((err) => reject(err));
  });
export const _getPartners = () =>
  new Promise<IPartnerSlider>((resolve: (res: IPartnerSlider) => void, reject: (err: any) => void) => {
    api
      .getPartners()
      .then((res) => {
        resolve(res.data as IPartnerSlider);
      })
      .catch((err) => reject(err));
  });
export const _getBuilderSlider = () =>
  new Promise<IBuilderSlider>((resolve: (res: IBuilderSlider) => void, reject: (err: any) => void) => {
    api
      .getBuilderSlider()
      .then((res) => {
        resolve(res.data as IBuilderSlider);
      })
      .catch((err) => reject(err));
  });
export const _getPoweredBy = () =>
  new Promise<IPoweredBy>((resolve: (res: IPoweredBy) => void, reject: (err: any) => void) => {
    api
      .getPoweredBy()
      .then((res) => {
        resolve(res.data as IPoweredBy);
      })
      .catch((err) => reject(err));
  });
export const _getLatest = () =>
  new Promise<IExplore>((resolve: (res: IExplore) => void, reject: (err: any) => void) => {
    api
      .latest()
      .then((res) => {
        resolve(res.data as IExplore);
      })
      .catch((err) => reject(err));
  });
export const _getAvatarRandom = (gender: number) =>
  new Promise<IGetRandomAvatar>((resolve: (res: IGetRandomAvatar) => void, reject: (err: any) => void) => {
    api
      .getGender({ gender })
      .then((res) => {
        resolve(res.data as IGetRandomAvatar);
      })
      .catch((err) => reject(err));
  });
export const _getAvatarJson = (id: number) =>
  new Promise<any>((resolve: (res: any) => void, reject: (err: any) => void) => {
    api
      .getAvatarJson({ id })
      .then((res) => {
        resolve(res as any);
      })
      .catch((err) => reject(err));
  });
export const _getDetail = (id: string | any) =>
  new Promise<IFeaturedItemDetail>((resolve: (res: IFeaturedItemDetail) => void, reject: (err: any) => void) => {
    api2
      .detailAsset({ id })
      .then((res) => {
        resolve(res.data as IFeaturedItemDetail);
      })
      .catch((err) => reject(err));
  });

export const _getBluePrintDetail = (id: string | any) =>
  new Promise<IBlueprintDetailProps>((resolve: (res: IBlueprintDetailProps) => void, reject: (err: any) => void) => {
    api
      .blueprintDetail({ id })
      .then((res) => {
        resolve(res.data as IBlueprintDetailProps);
      })
      .catch((err) => reject(err));
  });

export const _getPlanetDetails = (id: string | any) =>
  new Promise<IPlanetDetails>((resolve: (res) => void, reject: (err) => void) => {
    api
      .planetDetails({ id })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => reject(err));
  });

export const _getFeaturesProfile = (asset_type: string | any) =>
  new Promise<IFeatureProfile>((resolve: (res: IFeatureProfile) => void, reject: (err: any) => void) => {
    api
      .featureProfile({ asset_type })
      .then((res) => {
        resolve(res.data as any);
      })
      .catch((err) => reject(err));
  });

export const _getNewsDetail = (id: string) =>
  new Promise<INewsDetail>((resolve: (res: INewsDetail) => void, reject: (err: any) => void) => {
    api
      .news({ id })
      .then((res) => {
        resolve(res.data as INewsDetail);
      })
      .catch((err) => reject(err));
  });

export const _getCreatorProfile = (owner: any) =>
  new Promise<IProfileUser>((resolve: (res: IProfileUser) => void, reject: (err: any) => void) => {
    api
      .creatorProfile({ owner })
      .then((res) => {
        resolve(res.data as IProfileUser);
      })
      .catch((err) => reject(err));
  });
export const _getSignMessage = (data: string, fingerprint: string) =>
  new Promise<ISignMessage>((resolve: (res: ISignMessage) => void, reject: (err: any) => void) => {
    api
      .signMessage({ owner: data, finger_print: fingerprint })
      .then((res) => {
        resolve(res.data as ISignMessage);
      })
      .catch((err) => reject(err));
  });
export const _getWalletDetail = (owner: any, hash_signe: any, message: any, code?: string, finger_print?: string) =>
  new Promise<IWalletDetail>((resolve: (res: IWalletDetail) => void, reject: (err: any) => void) => {
    api
      .walletData({
        hash_signe: hash_signe,
        message: message,
        owner: owner,
        code: code,
        finger_print: finger_print,
      })
      .then((res) => resolve(res.data as IWalletDetail))
      .catch((err) => reject(err));
  });

export const _getInventoryProfile = (owner: string) =>
  new Promise<IProfileUser>((resolve: (res: IProfileUser) => void, reject: (err) => void) => {
    api
      .inventoryBio({ owner })
      .then((res) => resolve(res.data as IProfileUser))
      .catch((err) => reject(err));
  });
export const _sendQuoteForm = (form) =>
  new Promise((resolve: (res) => void, reject: (err) => void) => {
    api
      .sendQuote(form)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
export const _getDropDown = () =>
  new Promise<IDropdown>((resolve: (res) => void, reject: (err) => void) => {
    api
      .getDropdown()
      .then((res) => resolve(res.data as IDropdown))
      .catch((err) => reject(err));
  });
export const _getDistrictsList = () =>
  new Promise((resolve: (res) => void, reject: (err) => void) => {
    api
      .getDistrictsList()
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
export const _getDistrictsDetail = () =>
  new Promise((resolve: (res) => void, reject: (err) => void) => {
    api
      .getDistrictsDetail()
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
export const _getDistrictsPageDetail = () =>
  new Promise((resolve: (res) => void, reject: (err) => void) => {
    api
      .getDistrictsPageDetail()
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });

export const _getFilterByMarketplace = (data: IFilterByMarketplace) =>
  new Promise<IExplore>((resolve: (res: IExplore) => void, reject: (err) => void) => {
    api
      .filterByMarketplace(data)
      .then((res) => resolve(res.data as IExplore))
      .catch((err) => reject(err));
  });

export const _getMarketplaceDemoList = (data: IFilterByMarketplace) =>
  new Promise<IExplore>((resolve: (res: IExplore) => void, reject: (err) => void) => {
    api
      .getMarketplaceDemoList(data)
      .then((res) => resolve(res.data as IExplore))
      .catch((err) => reject(err));
  });

export const _sendGameVerification = (data: { token: any; wallet: any }) =>
  new Promise((resolve: (res) => void, reject: (err) => void) => {
    api
      .sendGameVerify(data)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
export const _getEarnMoreRovers = async () =>
  new Promise((resolve: (res) => void, reject: (err) => void) => {
    api
      .getEarnMoreRovers()
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });

export const _getExplore = (data: IFilterByMarketplace) => {
  if (data.currency || data.price_max || data.price_min || data.sort) {
    return new Promise<IExplore>(
      // eslint-disable-next-line sonarjs/no-identical-functions
      (resolve: (res: IExplore) => void, reject: (err) => void) => {
        api
          .filterByMarketplace(data)
          .then((res) => resolve(res.data as IExplore))
          .catch((err) => reject(err));
      },
    );
  } else {
    return new Promise<IExplore>((resolve: (res: IExplore) => void, reject: (err: any) => void) => {
      api
        .explor({
          category: data.category,
          offset: data.offset,
          limit: data.limit,
        })
        .then((res) => {
          resolve(res.data as IExplore);
        })
        .catch((err) => reject(err));
    });
  }
};

export const _getmarketplaceList = (data: IFilterByMarketplace, isDemo: boolean) => {
  if (isDemo) {
    return new Promise<IExplore>(
      // eslint-disable-next-line sonarjs/no-identical-functions
      (resolve: (res: IExplore) => void, reject: (err) => void) => {
        api
          .getMarketplaceDemoList(data)
          .then((res) => resolve(res.data as IExplore))
          .catch((err) => reject(err));
      },
    );
  } else {
    if (data.currency || data.price_max || data.price_min || data.sort) {
      return new Promise<IExplore>(
        // eslint-disable-next-line sonarjs/no-identical-functions
        (resolve: (res: IExplore) => void, reject: (err) => void) => {
          api
            .filterByMarketplace(data)
            .then((res) => resolve(res.data as IExplore))
            .catch((err) => reject(err));
        },
      );
    } else {
      return new Promise<IExplore>(
        // eslint-disable-next-line sonarjs/no-identical-functions
        (resolve: (res: IExplore) => void, reject: (err: any) => void) => {
          api
            .explor({
              category: data.category,
              offset: data.offset,
              limit: data.limit,
              district: data.district,
              parcel: data.parcel,
              ...data,
            })
            .then((res) => {
              resolve(res.data as IExplore);
            })
            .catch((err) => reject(err));
        },
      );
    }
  }
};
export const _getMetaTags = async (route: string) =>
  new Promise<IMetaTags>((resolve: (res: any) => void, reject: (err: any) => void) => {
    api
      .getMetaTags(route)
      .then((res) => {
        const response = {
          title: res.data[0].title,
          description:
            res.data[0].description !== null
              ? res.data[0].description
              : 'LandRocker is a play-to-earn game about discovery and space exploration where players compete to uncover valuable resources such as NFTs, crypto tokens, crafting materials and more.',
          imageUrl:
            res.data[0].image !== null
              ? `https://portal-admin.landrocker.io${res.data[0].image.url}`
              : 'https://cdn.landrocker.io/website_content/landing/metatag/Immutable_poster%20Version_04.jpg',
          isHome: res.data[0].isHome,
        };
        resolve(response as IMetaTags);
      })
      .catch((err) => reject(err));
  });

export const _getPromotionData = () =>
  new Promise((resolve: (res) => void, reject: (err) => void) => {
    api
      .getPromotionData()
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });

export const _getPromotionTicket = () =>
  new Promise<ITicket>((resolve: (res: ITicket) => void, reject: (err: any) => void) => {
    api
      .getChristmasTicket()
      .then((res) => {
        resolve(res.data as ITicket);
      })
      .catch((err) => reject(err));
  });
export const _getTaskDetails = (actionId: string) =>
  new Promise<ITaskDetailResponse>((resolve: (res: ITaskDetailResponse) => void, reject: (err: any) => void) => {
    api
      .getTaskDetails(actionId)
      .then((res) => {
        resolve(res.data as ITaskDetailResponse);
      })
      .catch((err) => reject(err));
  });
