import React, { memo } from 'react';
import dynamic from 'next/dynamic';
import styles from './VideoPlayer.module.scss';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const DynamicReactPlayer = dynamic(() => import('react-player'), {
  ssr: false,
});

interface IVideoPlayer {
  url: string;
  className?: string;
  autoPlay: boolean;
}
const YoutubeVideoPlayer = ({ url, className, autoPlay }: IVideoPlayer) => (
  <div className={`${styles.youtubePlayerContainer} ${className}`}>
    <DynamicReactPlayer
      style={{ borderRadius: '16px', objectFit: 'cover' }}
      width={'100%'}
      height={'100%'}
      url={url}
      playing={autoPlay}
      controls={true}
    />
  </div>
);
export default memo(YoutubeVideoPlayer);
