import React from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { SocialMediaTokenSale } from '@/components/TokenSale';
import Wrapper from '@/components/TokenSale/Wrapper';
import useTranslationStatus from '@/hooks/useTranslationStatus';

const JoinUsLayout = (props: any) => {
  const { t } = useTranslation('common');
  const router = useRouter();
  const { isReady } = useTranslationStatus();
  const getTranslation = (key) => isReady && t(key);

  const data = {
    order: '13',
    title: getTranslation('home:general.follow_us'),
    subTitle: `+300,000 ${getTranslation('home:general.members')}`,
    discordLink: 'https://discord.com/invite/landrocker',
    twitterLink: 'https://twitter.com/landrocker_io',
    mediumLink: 'https://landrocker.medium.com',
    telegramLink: 'https://t.me/landrockerchat',
    instagramLink: 'https://Instagram.com/landrocker.io',
    followButton: getTranslation('home:general.follow'),
    joinButton: getTranslation('home:general.join'),
  };
  return <SocialMediaTokenSale data={data} />;
};

export default JoinUsLayout;
