const ParseSentence = (sentence: string) => {
  const regex = /<(\d+)>(.*?)<\/\1>/g;
  const parts = [];
  let match;

  while ((match = regex.exec(sentence)) !== null) {
    const index = match[1];
    const content = match[2];
    parts.push(content);
  }
  return parts;
};
export default ParseSentence;
