/* eslint-disable import/no-anonymous-default-export */
import { ICategoryList, IFeaturedItemDetail } from '@/types/DTO/marketplace';
import { IGetAllMaterialsDefault, IGetAllPlanets, IGetMaterialsProps, IGetPlanetsProps, ILootboxCountdownProps } from '@/types/NewMarketplace';
import { axiosInstanceStrapi } from '../serviceStrapi';
import { axiosInstanceTwo } from '../serviceTest';

export default {
  getAllMaterials: (params: IGetMaterialsProps) =>
    axiosInstanceTwo.get<IGetAllMaterialsDefault>('api/materials/get/sell/list', {
      params,
    }),
  getAllPlanetList: (params: IGetPlanetsProps) =>
    axiosInstanceTwo.get<IGetAllPlanets>('/api/get/market/planets', {
      params,
    }),

  detailAsset: (params: { id: any }) =>
    axiosInstanceTwo.get<IFeaturedItemDetail>('api/get/assets/details/web', {
      params,
    }),

  advertisingMarketplaces: () => axiosInstanceStrapi.get('/data?params=advertising-marketplaces'),
  categoryList: () => axiosInstanceTwo.get<ICategoryList>('/api/get/asset/type'),
  getLootboxCountdown: () => axiosInstanceStrapi.get<ILootboxCountdownProps>('/data?params=loot-box-count-downs'),
  getRoverPrice: () => axiosInstanceTwo.get('api/asset/sell/rover/round'),
  getCollectionList: (params: { chain?: string }) =>
    axiosInstanceTwo.get('api/get/collection', {
      params,
    }),

  getCollectionDetail: (params: { id: string }) => axiosInstanceTwo.get(`api/get/getCollection/byId?id=${params.id}`),
};
