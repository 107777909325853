import React, { ReactNode, useEffect } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import styles from './SocialMedia.module.scss';

interface ISocialMediaItemProps {
  icon: ReactNode;
  link: string;
  textBTN: string;
  name: string;
}

const SocialMediaItem = (props: ISocialMediaItemProps) => {
  const { icon, link, textBTN, name } = props;
  const router = useRouter();

  return (
    <div className={styles.boxSocialmedia}>
      <div className={styles.icon}>{icon}</div>
      <div className={styles.boxData}>
        <Link href={link} target='_blank'>
          <a className={`gtm-follow_us-${name} `} aria-label={textBTN} target='_blank'>
            <button className='capitalize button-hover-effect'>{textBTN}</button>
          </a>
        </Link>
      </div>
    </div>
  );
};

export default SocialMediaItem;
