import React, { ReactElement } from 'react';
import cn from 'classnames';
import classNames from 'classnames';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { Typography } from '@/components/Atoms';
import styles from './CardTokenSale.module.scss';
import useTranslationStatus from '@/hooks/useTranslationStatus';

export interface ICardTokenSaleProps {
  descriptions?: string[];
  cardItems: string[];
  icon: ReactElement;
  title: string;
  link?: string;
}
const CardTokenSale = (props: { item: any; type: 'yellow' | 'green'; className?: string }) => {
  // translation of this file is from strapi.io no front end file exist
  const { t } = useTranslation('home');
  const { isReady } = useTranslationStatus();
  const { item, type, className } = props;
  const router = useRouter();
  let specialText = 'Collect valuable NFTs, Crypto, Materials, and Blueprints.';
  const currentLanguage = router.locale;

  const BoxCardClasses = classNames({
    'h-auto md:h-[330px] lg:h-[280px]': currentLanguage === 'en',
    'h-auto sm:h-[300px] lg:h-[380px]': ['id', 'fr', 'ru'].includes(currentLanguage),
    'h-auto sm:h-[300px] md:h-[350px] lg:h-[340px]': ['tr', 'pt'].includes(currentLanguage),
  });

  const BoxButtonClasses = classNames({
    'md:w-[150px] lg:w-[175px] w-[130px] px-1': ['id', 'ru'].includes(currentLanguage),
    'md:w-[115px] lg:w-[125px] w-[92px]': ['en', 'tr', 'pt', 'fr'].includes(currentLanguage),
  });

  return (
    // <div className='relative w-[120px] h-[120px]'>
    //   <Image layout={'fill'} objectFit='contain' src={`${process.env.NEXT_PUBLIC_STRAPI_MEDIA}/${item.icon}`} alt='icon' />
    // </div>

    <div className={cn(styles.boxCard, className, BoxCardClasses)}>
      <div className={styles.boxCardContainer}>
        <div className={styles.boxHeader}>
          {/* <div className={styles.iconBox}>
            <Image layout={'fill'} src={`${process.env.NEXT_PUBLIC_STRAPI_MEDIA}/${item.icon}`} alt='icon' />
          </div> */}

          <div className='relative w-[50px] h-[50px]'>
            <Image layout={'fill'} objectFit='contain' src={`${process.env.NEXT_PUBLIC_STRAPI_MEDIA}/${item.icon}`} alt='icon' />
          </div>

          <Typography size='s28ToS18' className={styles.title} style={{ color: '#D9CE6A' }}>
            {item.title}
          </Typography>
        </div>
        <div className={styles.boxCardItemContainer}>
          {item?.descriptions?.map((cardItem, index) => (
            <div key={index} className={styles.boxCardItem}>
              <div className={styles['circle-yellow']}></div>
              <Typography size='s22ToS14' className={`mx-[24px] ${cardItem === specialText ? 'leading-[30px]' : 'leading-[38px]'} `}>
                {cardItem}
              </Typography>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.boxButton}>
        {item.link !== undefined && (
          <button
            className={classNames(BoxButtonClasses, `gtm-learnmore-${item.title}`)}
            onClick={() => {
              router.push(item.link);
            }}
          >
            {isReady && t('home:general.learn_more')}
          </button>
        )}
      </div>
    </div>
  );
};

export default CardTokenSale;
